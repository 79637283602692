import {NgModule, ModuleWithProviders} from '@angular/core'
import {CommonModule} from '@angular/common'
import {AuthGuardService} from './services/auth-guard.service'
import {AuthService} from './services/auth.service'
import {TestGuardService} from './services/test-guard.service'
import {JwtModule, JwtHelperService, JWT_OPTIONS} from '@auth0/angular-jwt'
import {ErrorHandlerService} from './services/error-handler.service';
import { AlertsComponent } from './components/alerts/alerts.component'
import { MaterialModule } from 'src/app/material/material.module';

@NgModule({
  imports: [CommonModule, MaterialModule],
  providers: [
    {provide: JWT_OPTIONS, useValue: JWT_OPTIONS},
    JwtHelperService
  ],
  declarations: [
    AlertsComponent
  ]
  // exports: [NgxPermissionsModule]
})
export class SharedModule {
  static forRoot (): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [AuthGuardService, AuthService, TestGuardService, JwtModule, ErrorHandlerService]
    }
  }


}
