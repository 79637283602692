import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit{
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }
  
  public icon: string = 'warning'

  ngOnInit(): void {
    if(this.data.type === 'danger')
      this.icon = 'cancel'
    if(this.data.type === 'success')
      this.icon = 'check_circle'
    if(this.data.type === 'info')
      this.icon = 'info'
  }


}
