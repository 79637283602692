import {NgModule} from '@angular/core'
import {Routes, RouterModule} from '@angular/router'
import {NotFoundComponent} from './not-found/not-found.component'
import {ForbiddenComponent} from './forbidden/forbidden.component'

const routes: Routes = [
  {
    path: '', redirectTo: 'public', pathMatch: 'full'
  },
  {
    path: 'public', 
    loadChildren: () => import('./public/public.module').then(m => m.PublicModule)
  },
  {
    path: 'private', 
    loadChildren: () => import('./private/private.module').then(m => m.PrivateModule)
  },
  {
    path: 'forbidden', component: ForbiddenComponent
  },
  {
    path: 'notFound', component: NotFoundComponent
  },
  {
    path: '**', component: NotFoundComponent
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
