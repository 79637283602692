import {Injectable} from "@angular/core"
import {JwtHelperService} from "@auth0/angular-jwt"
import {Router} from '@angular/router'
import axios from 'axios'
import {environment} from 'src/environments/environment'
import {NgxPermissionsService} from 'ngx-permissions'
import {ErrorHandlerService} from './error-handler.service'
import { of } from "rxjs"
// import {Observable} from "rxjs"

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  cupo: {sms: string, cb: string, wa: string} = {sms: '0', cb: '0', wa: '0'}

  constructor (
    public jwtHelper: JwtHelperService,
    private router: Router,
    private _ngxPermissions: NgxPermissionsService,
    private _errorHandlerService: ErrorHandlerService
  ) {
  }

  public decodeLocalToken () {
    const token = this.getLogin()
    return this.jwtHelper.decodeToken(token)
  }

  public isAuthenticated (): void | boolean | undefined {
    const token = this.getLogin()
    // console.log("token", this.jwtHelper.isTokenExpired(token))
    try {
      if (this.jwtHelper.isTokenExpired(token)) {
        localStorage.clear()
        return false
        // this.logOut()
      }
    } catch (e) {
      // this.logOut()
      localStorage.clear()
      return false
    }
    return true
  }

  public setLogin (token: string): void {
    localStorage.setItem("token", token)
  }
  public getLogin (): string {
    return localStorage.getItem("token") || ''
  }
  public setRemoveToken (): void {
    return localStorage.removeItem("token")
  }

  public async updateCupo(){
    await this.refreshToken()
    const decodedToken = this.decodeLocalToken()
    for (const messageType of decodedToken.messageTypes) {
      if (messageType.type === 'sms')
        this.cupo.sms = messageType.currentStock.stock
      if (messageType.type === 'cb')
        this.cupo.cb = messageType.currentStock.stock
      if (messageType.type === 'wa')
        this.cupo.wa = messageType.currentStock.stock
    }
  }

  public getCupo(){
    return of(this.cupo)
  }

  public logOut (): void {
    localStorage.clear()
    this.router.navigate(["/public/login"], {replaceUrl: true})
  }

  public permissions (token?: string): any {
    const tn = token || this.getLogin()
    return axios
      .post(`${environment.apiurl}/permisos`, {}, {
        headers: {Authorization: 'Bearer ' + tn},
      })
      .then((res) => res.data)
      .catch((err) => {
        throw err.response.data
      })
  }

  public async refreshToken () {
    try {
      const res = await this.getNewToken()
      this.setRolesAndPermissions(res.token)
      this.setLogin(res.token)
    } catch (e) {
      throw this._errorHandlerService.getError(e)
    }
  }
  private async getNewToken () {
    try {
      const res = await axios
        .get(`${environment.apiurl}/user/refreshToken`, {
          headers: {Authorization: 'Bearer ' + this.getLogin()}
        })
      return res.data
    }
    catch (e) {
      throw this._errorHandlerService.getError(e)
    }

  }

  public setRolesAndPermissions (token: string = this.getLogin()) {
    // const token = inToken ?? this.getLogin()
    // console.log("auth 95", token)
    const decodedToken = this.jwtHelper.decodeToken(token)
    const permissions = []
    for (let permission of decodedToken.permissions) {
      permissions.push(permission)
    }
    this._ngxPermissions.loadPermissions(permissions)
    // console.log(this._ngxPermissions.getPermissions())
  }

  public async userHasPermission (permission: string) {
    return await this._ngxPermissions.hasPermission(permission)
  }
}
