import {BrowserModule} from '@angular/platform-browser'
import {NgModule} from '@angular/core'
import {FormsModule} from '@angular/forms'

import {MaterialModule} from './material/material.module'
import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {SharedModule} from './shared/shared.module'
import {NotFoundComponent} from './not-found/not-found.component'
import {ForbiddenComponent} from './forbidden/forbidden.component'
import {ReactiveFormsModule} from '@angular/forms'
import {NgxPermissionsModule} from 'ngx-permissions'
import {MatTableModule} from '@angular/material/table'
import { HttpClientModule } from '@angular/common/http'

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    ForbiddenComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    SharedModule.forRoot(),
    FormsModule,
    NgxPermissionsModule.forRoot(),
    MatTableModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
